import {useTheme} from 'styled-components';

const InsightLogo = () => {
  const theme = useTheme();
  const color = theme.dark ? '#FBFBFF' : '#252525';

  return (
    <svg width='89' height='27' viewBox='0 0 89 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.67526 21.1347H0L2.66348 6.24684H7.33875L4.67526 21.1347ZM3.07434 2.49048C3.05545 1.82085 3.2963 1.26129 3.79688 0.811815C4.30691 0.362335 4.92083 0.137596 5.63865 0.137596C6.36591 0.137596 6.97039 0.339403 7.45209 0.743017C7.94322 1.14663 8.19824 1.67408 8.21713 2.32537C8.23602 3.00417 7.99517 3.56832 7.49459 4.01779C6.994 4.4581 6.38008 4.67825 5.65282 4.67825C4.94444 4.67825 4.33997 4.48103 3.83938 4.08659C3.34824 3.68298 3.09323 3.15094 3.07434 2.49048Z'
        fill={color}
      />
      <path
        d='M14.55 6.23309L14.2241 8.02183C15.452 6.60918 16.9301 5.9212 18.6585 5.95789C20.0753 5.98541 21.1284 6.4991 21.8179 7.49897C22.5168 8.48965 22.7718 9.91148 22.5829 11.7644L20.9678 21.1347H16.2926L17.9218 11.7369C17.9596 11.4159 17.9691 11.1223 17.9502 10.8563C17.8841 9.98486 17.3551 9.54914 16.3634 9.54914C15.5417 9.5308 14.7908 9.88396 14.1108 10.6086L12.1982 21.1347H7.53709L10.2006 6.24684L14.55 6.23309Z'
        fill={color}
      />
      <path
        d='M32.2876 16.8692C32.4104 16.2638 31.9287 15.8097 30.8426 15.507L29.8367 15.2594C28.1933 14.8374 26.989 14.2457 26.224 13.4844C25.4589 12.723 25.1 11.8195 25.1472 10.7737C25.1945 9.34275 25.8462 8.18236 27.1024 7.29257C28.368 6.39361 29.9075 5.95331 31.7209 5.97165C33.5249 5.99 34.9842 6.43489 36.0987 7.30633C37.2226 8.17777 37.7941 9.35192 37.813 10.8288L33.166 10.815C33.2038 9.64087 32.6702 9.0538 31.5651 9.0538C31.0929 9.0538 30.6537 9.19139 30.2475 9.46658C29.8414 9.7326 29.6006 10.0904 29.525 10.5398C29.3644 11.2828 30.0397 11.792 31.5509 12.0671C32.8638 12.3699 33.8791 12.723 34.5969 13.1266C35.3148 13.5211 35.8626 14.0118 36.2404 14.5989C36.6182 15.186 36.7929 15.8923 36.7646 16.7179C36.7362 17.6168 36.434 18.4195 35.8578 19.1258C35.2817 19.8321 34.4458 20.3963 33.3502 20.8182C32.264 21.2402 31.0834 21.4374 29.8083 21.4099C28.7033 21.4007 27.6738 21.176 26.7198 20.7357C25.7753 20.2954 25.0339 19.6991 24.4955 18.9469C23.9666 18.1856 23.6927 17.3141 23.6738 16.3326L28.0091 16.3601C28.028 17.681 28.708 18.3323 30.0492 18.314C30.6253 18.314 31.1165 18.1902 31.5226 17.9425C31.9287 17.6856 32.1838 17.3279 32.2876 16.8692Z'
        fill={color}
      />
      <path
        d='M43.1258 21.1347H38.4505L41.114 6.24684H45.7892L43.1258 21.1347ZM41.5248 2.49048C41.5059 1.82085 41.7468 1.26129 42.2474 0.811815C42.7574 0.362335 43.3713 0.137596 44.0891 0.137596C44.8164 0.137596 45.4209 0.339403 45.9026 0.743017C46.3937 1.14663 46.6487 1.67408 46.6676 2.32537C46.6865 3.00417 46.4457 3.56832 45.9451 4.01779C45.4445 4.4581 44.8306 4.67825 44.1033 4.67825C43.3949 4.67825 42.7905 4.48103 42.2899 4.08659C41.7987 3.68298 41.5437 3.15094 41.5248 2.49048Z'
        fill={color}
      />
      <path
        d='M46.8801 13.6633C47.0596 12.168 47.4799 10.815 48.141 9.60418C48.8116 8.39334 49.6475 7.48062 50.6487 6.86602C51.6498 6.24226 52.7832 5.93955 54.0489 5.95789C55.5884 5.99459 56.7501 6.53121 57.5341 7.56776L58.0299 6.23309L62.3368 6.24684L59.8292 20.5568C59.5458 22.5749 58.6344 24.1664 57.0949 25.3314C55.5553 26.4963 53.638 27.0513 51.3429 26.9963C50.3228 26.9779 49.3358 26.7807 48.3819 26.4046C47.4279 26.0285 46.611 25.4598 45.9309 24.6984L48.141 22.0291C48.9628 23.0381 50.03 23.5564 51.3429 23.5839C53.4113 23.6206 54.7242 22.5519 55.2814 20.3779L55.3948 19.9239C54.3369 20.9329 53.1705 21.4282 51.8954 21.4099C50.9131 21.4007 50.0489 21.1622 49.3028 20.6944C48.5566 20.2174 47.971 19.534 47.546 18.6442C47.121 17.7544 46.8801 16.7637 46.8235 15.6721C46.7857 15.1218 46.8046 14.4521 46.8801 13.6633ZM51.4846 15.1355C51.4846 15.9794 51.6404 16.6353 51.9521 17.1031C52.2638 17.571 52.7171 17.8141 53.3122 17.8324C54.1905 17.8691 54.9886 17.5389 55.7065 16.8417L56.9107 10.5261C56.5329 9.88396 55.9001 9.55373 55.0123 9.53538C54.1055 9.51704 53.3452 9.89313 52.7313 10.6637C52.1174 11.425 51.7301 12.4295 51.5696 13.677C51.5129 14.2549 51.4846 14.7411 51.4846 15.1355Z'
        fill={color}
      />
      <path
        d='M69.1797 7.87047C70.3226 6.57707 71.6401 5.93955 73.1324 5.95789C74.672 5.99459 75.8101 6.54038 76.5468 7.59528C77.293 8.65018 77.5621 10.0812 77.3543 11.8883L75.7676 21.1347H71.1065L72.7074 11.8332C72.7452 11.5213 72.7546 11.2278 72.7358 10.9526C72.6696 10.0445 72.1502 9.57666 71.1773 9.54914C70.3367 9.5308 69.5906 9.82892 68.9389 10.4435L66.9838 21.1347H62.3227L66.1054 0H70.7665L69.1797 7.87047Z'
        fill={color}
      />
      <path
        d='M87.2149 2.54552L86.549 6.24684H89L88.4191 9.46658H85.9682L84.7781 16.3051C84.712 16.7729 84.7403 17.1261 84.8631 17.3646C84.9953 17.6031 85.3165 17.7315 85.8265 17.7498C86.0248 17.759 86.431 17.7361 87.0449 17.681L86.7049 21.0384C85.9209 21.2861 85.0851 21.4007 84.1972 21.3824C82.7522 21.364 81.6707 20.9512 80.9529 20.144C80.2351 19.3368 79.9423 18.2406 80.0745 16.8555L81.3212 9.46658H79.4228L79.9895 6.24684H81.8879L82.5538 2.54552H87.2149Z'
        fill={color}
      />
    </svg>
  );
};

export default InsightLogo;
